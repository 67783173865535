import {
  kpEndpoint, kpParams,
  kpRequest,
} from '@/axios'

export const FETCH_CENNECTION_REQUESTS = 'fetchConnectionRequests'
export const UPDATE_CONNECTION_REQUEST = 'updateConnectionRequest'
export const FETCH_DIRECT_RETAILERS = 'fetchDirectRetailers'
export const GET_MARKETPLACE_DIRECT_USERS = 'getMarketplaceDirectUsers'
export const SEND_CENNECTION_REQUEST = 'sendConnectionRequest'
export const GET_CONNECTION_INFO = 'getConnectionInfo'
export const IMPORT_DIRECT_USERS = 'importDirectUsers'
export const INVITE_RETAILER = 'inviteRetailer'
export const INVITE_RETAILER_FROM_LEADAI = 'inviteRetailerFromLeadAI'
export const ADD_RETALIER = 'addRetailer'

const state = {
  directRetailers: [],
}

const actions = {
  async [FETCH_DIRECT_RETAILERS](ctx) {
    return await kpRequest({ ...kpEndpoint.wallet.get }).then(res => {
      const data = res?.data?.data
      ctx.state.directRetailers = data?.directRetailers || []
      return data?.directRetailers || []
    })
  },
  async [FETCH_CENNECTION_REQUESTS](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.user.connection.connectionRequest, params })
  },
  async [UPDATE_CONNECTION_REQUEST](ctx, { requestId, payload = {} }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.user.connection.updateRequestConnection, requestId),
      payload,
    })
  },
  async [SEND_CENNECTION_REQUEST](ctx, payload = {}) {
    return await kpRequest({ ...kpEndpoint.user.connection.sendRequestConnection, payload })
  },
  async [GET_CONNECTION_INFO](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.user.connection.getConnectionInfo, params })
  },
  async [GET_MARKETPLACE_DIRECT_USERS]() {
    return await kpRequest({ ...kpEndpoint.wallet.get })
  },

  async [IMPORT_DIRECT_USERS](ctx, { payload = {}, params = {} }) {
    return await kpRequest({
      ...kpEndpoint.user.connection.import, payload, params,
    })
  },
  async [INVITE_RETAILER](ctx, { payload = {} }) {
    return await kpRequest({
      ...kpEndpoint.user.connection.inviteRetailer, payload,
    })
  },
  async [INVITE_RETAILER_FROM_LEADAI](ctx, { payload = {} }) {
    return await kpRequest({
      ...kpEndpoint.user.connection.inviteRetailerFromLeadAI, payload,
    })
  },
  async [ADD_RETALIER](ctx, { payload = {} }) {
    return await kpRequest({
      ...kpEndpoint.user.connection.addRetailer, payload,
    })
  },
}

export default {
  actions,
  state,
}
