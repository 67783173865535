import Vue from 'vue'
import Vuex from 'vuex'
/* eslint-disable*/
// Modules
import shop from './modules/shop.module'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './modules/auth.module'
import brand from './modules/brand.module'
import order from './modules/order.module'
import signup from './modules/signup.module'
import toolkit from './modules/toolkit.module'
import notification from './modules/notification.module'
import connections from './modules/connections.module'
import collection from './modules/collection.module'
import shipment from './modules/shipment.module'
import wallet from './modules/wallet.module'
import referral from './modules/referral.module'
import common from './modules/common.module'
import checkout from './modules/checkout-v2.module'
import salesAnalytics from './modules/sales-analytics.module'
import multiSelect from './modules/multi-select.module'
import wishlist from './modules/wishlist.module'
import leadai from './modules/leadai.module'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		shop,
		auth,
		brand,
		order,
		signup,
		toolkit,
		notification,
		connections,
		collection,
		shipment,
		wallet,
		referral,
		common,
		checkout,
		salesAnalytics,
		multiSelect,
		wishlist,
		leadai,
	}
})

export function useStore() {
	if (store) return store
}

export default store
