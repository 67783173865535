export const RESET_SIGNUP_DETAILS = 'resetSignupDetails'
export const SET_IS_ADMIN_SIGNUP = 'setIsAdminSignup'

const getDefaultSignupData = () => ({
        role: '',
        entityName: '',
        firstName: '',
        lastName: '',
        country: '',
        phone: '',
        countryCode: '',
        email: '',
        password: '',
        password2: '',
        signupStartTime: Date.now(),
    })

export default {
    state: {
        signupDetails: getDefaultSignupData(),
        isAdminSignUp: false,
    },
    mutations: {
        [RESET_SIGNUP_DETAILS](state) {
            state.signupDetails = getDefaultSignupData()
            state.isAdminSignUp = false
        },
        [SET_IS_ADMIN_SIGNUP](state) {
            state.isAdminSignUp = true
        },
    },
    getters: {
        getSignupDetails(state) {
            return state.signupDetails
        },
        isAdminSignUp(state) {
            return state.isAdminSignUp
        },
    },
}
