export const getAuthDetails = data => {
    let { entityType } = data
    if (!entityType) {
        entityType = data.role
    }

    return {
        ...data,
        entityType,
    }
}

export const getAuthDetailsFromUserAndEntity = ({ userData, entityData }) => ({
    ...entityData,
    id: userData.id,
    lastLoggedUserAssociationId: userData.lastLoggedUserAssociationId,
    commercialTerms: userData.commercialTerms,
    firstName: userData.firstName,
    lastName: userData.lastName,
    role: userData.role,
    entityType: userData.entityType,
    userAssociations: userData.userAssociations,
    shouldDisplayToolKit: userData.shouldDisplayToolKit || entityData.shouldDisplayToolKit || false,
})
